import {Link} from "react-router-dom";
import "./About.css";

function About() {
    return (
        <div>
            <h1>Nice to meet you, we're Flannelgraph!</h1>
	        <p>Flannelgraph is an Indie, Christian Game Developer. The idea of Flannelgraph had been around for a long time before it had a name. Benjamin Schmidt, the creator of Flannelgraph (and writer of this page) started with a simple idea: make games that are actually fun to play and share Godly values.</p>
            <p>While working towards a Computer Science degree at Virginia Tech from 2018-2021, Benjamin made connections with <a href="https://weirddisciple.com/">Weird Disciple Publishing</a>. Flannelgraph then started in spirit. With the begginings of game design discussions, starting training and development, and participating in game jams, we started to build the skills needed to make the games we imagined we would.</p>
	        <p>Currently we are working with Weird Disciple Publishing on a platforming game called Cross Surfer, with many more ideas and collaborations on the wings.</p>
	        <p>Our current goal is to continue to grow in development knowledge and skills so that we can start building traction, but we have to start actually getting games out at some point! <Link to="/devblog">Follow us here</Link> as we continue to make progress, and hopefully we'll have something fun to present soon!</p>
	        <p>Thanks for checking us out!</p>
            <p>God Bless,<br></br>Benjamin Schmidt</p>
        </div>
    );
}

export default About;