import './Footer.css';

function Footer() {
    return (
      <footer className="Footer">
        <ul>
          <li><a href="https://github.com/Flannelgraph">Flannelgraph GitHub</a></li>
        </ul>
      </footer>
    );
}

export default Footer;