import { Outlet} from "react-router-dom";
import './Layout.css';
import MainHeader from "../../components/_header/MainHeader";
import Footer from "../../components/_footer/Footer";


const Layout = () => {
  return (
    <div className="LayoutWrapper">
      <MainHeader />
      <div className="OutletWrapper">
        <Outlet />
      </div>
      <Footer />
    </div>
  )
};

export default Layout;
