import {Link} from "react-router-dom";
import {useEffect, useState} from 'react';
import "./DevBlog.css";

function GetPosts(){
    
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch('https://flannelgraph.net/api/post')
    .then((response) => response.json())
    .then((data) => {
      console.log(JSON.stringify(data));
      setPosts(data);
    })
    .catch((err) => {
      console.log(err.message);
    });
  }, []);


  // Loading screen
  if (posts.length === 0) return (<p>Loading...</p>);

  // Actual post output
  return (
  <>
    {posts.map(post => {
      var path = `/devblog/${post._id}`;
      var date = new Date(post.dateCreated);
      return <li key={post._id} className="PostItem"><Link to={path} className="PostBlockLink"><div className="PostBlock">
        <h1 className="PostBlockTitle">{post.title}</h1>
        <p className="PostBlockDate">{date.toDateString()}, {date.getHours()%12}{date.getHours() - 12 > 0 ? "pm":"am"} EST</p>
      </div></Link></li>;
    })}
  </>);
}

function DevBlog() {
    return (
        <ul>
            <GetPosts />
        </ul>
    );
}

export default DevBlog;