import {Link} from "react-router-dom";
import './Home.css';

function Home() {
  return (
    <div className="blurb">
      <h1>Welcome to Flannelgraph!</h1>
      <p>Flannelgraph is an independent game developer who's goal is to make, quite simply, fun games! We're just getting started, but we look forward to giving the world something truly inspiring.</p>
      <p>We're proud to announce a collaboration with <a href="https://weirddisciple.com/">Weird Disciple Publishing</a> to make a new fighting game! We'll keep people updated on our progress at our <Link to="/devblog">DevBlog</Link>, and Weird Disciple Publishing will have updates on their site as well, along with ways to get involved. We look forward to working with them, and can't wait to see what we accomplish together!</p>	
    </div>
  );
}

export default Home;