import "./Err404.css";

function Err404() {
    return (
        <div>
            <h1>404 Error</h1>
            <p>Page not found.</p>
        </div>
    );
}

export default Err404;