import { Link, useParams } from "react-router-dom";
import {useEffect, useState} from 'react';
import "./DevBlogPost.css";

function GetPost(postID){
  console.log(postID.id);
  const [post, setPost] = useState(null);
  
  useEffect(() => {
    fetch(`https://flannelgraph.net/api/post/${postID.id}`)
    .then((response) => response.json())
    .then((data) => {
      console.log(JSON.stringify(data));
      setPost(data);
    })
    .catch((err) => {
      console.log(err.message);
    });
  }, [postID.id, setPost]);

  if (post === null) return (<p>Loading...</p>);

  var dateString;
  var date = new Date(post.dateCreated);
  if (post.dateCreated === post.lastUpdated) {
    dateString = <p className="PostBlockDate">Posted: {date.toDateString()}, {date.getHours()%12}{date.getHours() - 12 > 0 ? "pm":"am"} EST</p>
  }
  else {
    var updateDate = new Date(post.lastUpdated);
    dateString = <p className="PostBlockDate">Posted: {date.toDateString()} - Updated: {updateDate.toDateString()}, {updateDate.getHours()%12}{updateDate.getHours() - 12 > 0 ? "pm":"am"} EST</p>
  }

  let bodyArray = post.body.split("\n");

  return (
  <div>
    <h1>{post.title}</h1>
    {dateString}
    {bodyArray.map(body => <p>{body}</p>)}
  </div>);
}

function DevBlogPost() {
    let params = useParams();
    return (
        <div id="PostDiv">
            <Link to="/devblog" className="BackLink"><div className="BackButton">Back to other posts</div></Link>
            <GetPost id={params.postID}/>
        </div>
    );
}

export default DevBlogPost;