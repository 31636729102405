import {Link, useLocation} from "react-router-dom";
import './Header.css';
import { useEffect, useState } from "react";

function MainHeader() {

  const [menuOpen, setMenuOpen] = useState(false);
  
  let location = useLocation();
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);


  return (
    <header className="Header">
      <button type='button' className='HamburgerButton' onClick={() => {setMenuOpen(!menuOpen)}}></button>
      <h1 className='HeaderTitle' style={{marginLeft: menuOpen?  '13%' : '0px'}}>
        <Link className='HeaderTitleLink' to="/">Flannelgraph</Link>
        </h1>
      <div className="Screen" style={{
        backgroundColor: menuOpen? '#494949ad': '#49494900',
        visibility: menuOpen? 'visible' : 'hidden'
      }}
        onMouseDown={() => setMenuOpen(false)}></div>
      <nav className="HamburgerMenu" style={{visibility: menuOpen? 'visible' : 'hidden'}}>
        <ul className="HambugerMenuList">
          <li>
            <Link className='HamburgerLink' to="/">Home</Link>
         </li>
          <li>
            <Link className='HamburgerLink' to="/about">About</Link>
          </li>
          <li>
            <Link className='HamburgerLink' to="/devblog">DevBlog</Link>
          </li>
        </ul>
      </nav>
    </header>
);
}

export default MainHeader;